import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { Select } from "components/miloDesignSystem/molecules/select";
import { CountryCode, countryCodes } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  tradingDocument: TradingDocument;
  close: () => void;
}

export const TransportInfo = ({ tradingDocument, close }: Props) => {
  const countriesOptions = countryCodes.map(country => ({
    icon: country.icon,
    text: `${country.value} (${country.name})`,
    type: MenuItemType.ICON,
    value: country.value,
  }));
  return (
    <Modal
      close={close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Uzupełnij dokumenty przewozowe
        </Typography>
      }
    >
      <div>
        <div className="mx-3 mb-3">
          <Typography fontSize="10" fontWeight="700" className="text-uppercase mb-3">
            Środek transportu
          </Typography>

          <TextField.Async
            containerClassName="mb-3"
            label="Rodzaj pojazdu (WDT)"
            size="default"
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={carBrand => ({
              id: tradingDocument.id,
              carBrand,
            })}
            value={tradingDocument.carBrand}
          />
          <div className="d-flex align-items-center gap-3">
            <TextField.Async
              containerClassName="w-100"
              label="Nr rejestracyjny pojazdu (WDT, specyfikacja)"
              mutationHook={usePatchTradingDocumentMutation}
              size="default"
              transformQueryData={carRegistrationNumber => ({
                id: tradingDocument.id,
                carRegistrationNumber,
              })}
              value={tradingDocument.carRegistrationNumber}
            />
            <TextField.Async
              containerClassName="w-100"
              label="Nr naczepy (WDT, specyfikacja)"
              mutationHook={usePatchTradingDocumentMutation}
              size="default"
              transformQueryData={trailerRegistrationNumber => ({
                id: tradingDocument.id,
                trailerRegistrationNumber,
              })}
              value={tradingDocument.trailerRegistrationNumber}
            />
          </div>
        </div>
        <div className="mx-3 mb-3">
          <Typography fontSize="10" fontWeight="700" className="text-uppercase mb-3">
            Przewoźnik
          </Typography>

          <TextField.Async
            containerClassName="mb-3"
            label="Nazwa przewoźnika (CMR)"
            size="default"
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={carrierName => ({
              id: tradingDocument.id,
              carrierName,
            })}
            value={tradingDocument.carrierName}
          />
          <TextField.Async
            containerClassName="mb-3"
            label="Adres"
            size="default"
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={carrierStreet => ({
              id: tradingDocument.id,
              carrierStreet,
            })}
            value={tradingDocument.carrierStreet}
          />
          <div className="d-flex align-items-center gap-3">
            <TextField.Async
              containerClassName="flex-1"
              label="Kod pocztowy"
              mutationHook={usePatchTradingDocumentMutation}
              size="default"
              transformQueryData={carrierPostCode => ({
                id: tradingDocument.id,
                carrierPostCode,
              })}
              value={tradingDocument.carrierPostCode}
            />
            <TextField.Async
              containerClassName="flex-3"
              label="Miejscowość"
              mutationHook={usePatchTradingDocumentMutation}
              size="default"
              transformQueryData={carrierCity => ({
                id: tradingDocument.id,
                carrierCity,
              })}
              value={tradingDocument.carrierCity}
            />
            <div className="flex-2">
              <Select.Async
                items={countriesOptions}
                label="Kod kraju"
                selected={tradingDocument.carrierCountryCode}
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={carrierCountryCode => ({
                  id: tradingDocument.id,
                  carrierCountryCode: carrierCountryCode as CountryCode,
                })}
                textFieldProps={{
                  size: "default",
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center gap-2 p-3">
          <Button
            className="text-uppercase"
            onClick={close}
            size="medium"
            type="submit"
            variant="deepPurple"
          >
            Gotowe
          </Button>
        </div>
      </div>
    </Modal>
  );
};
